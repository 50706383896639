import React, { useEffect, useState } from 'react'
import "../MyPatients/MyPatients.css"
import "../../Assets/Css/Responsive.css"
import { Box, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material'
import ButtonComponent from '../../Components/ButtonComponent/ButtonComponent'
import AddIcon from '@mui/icons-material/Add';
import AddControllers from './Add controllers/AddControllers';
import { GetApi } from '../../Api/Api';
import ControllerTable from './components/ControllerTable';
import { Toaster } from 'react-hot-toast'
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../Context/SearchContext";

const Controllers = () => {
    const [open, setOpen] = useState(false);
    const [controllerData, setControllerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { searchQuery, setSearchQuery } = useSearch();

    const fetchControllerData = async (page, rowsPerPage) => {
        const endpoint = `/controllers?page=${page}&rowperpage=${rowsPerPage}`;
        try {
            const response = await GetApi(endpoint);
            setControllerData(response.data.data);
            setPaginationData(response.data.pagination)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchControllerData(currentPage + 1, rowsPerPage);
    }, [currentPage, rowsPerPage]);


    const AddUpdateController = () => {
        fetchControllerData(currentPage + 1, rowsPerPage);
    };


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Box>
            <Toaster position="top-right" reverseOrder={false} />
            <Box className="my-patients-topbar" mt={3}>
                <Box>
                    <Typography className='title' component="h4">Controller</Typography>
                </Box>
                <Box className="right btn-font-small">
                    <Stack direction="row" gap={2}>
                        <Paper component="form" className="search-input">
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="search"
                                inputProps={{ "aria-label": "search google maps" }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                <SearchIcon sx={{ color: "var(--whiteLight)" }} />
                            </IconButton>
                        </Paper>
                        <ButtonComponent
                            buttonText="Add Controller"
                            buttonVariant="contained" buttonStyle={{ borderRadius: "100px", }}
                            startIcon={<AddIcon />}
                            onClick={handleOpen}
                        />
                    </Stack>
                </Box>
            </Box>
            <Box sx={{ mt: '30px' }}>
                <ControllerTable
                    controllerData={controllerData}
                    AddUpdateController={AddUpdateController}
                    loading={loading}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    currentPage={currentPage}
                    paginationData={paginationData}
                />
            </Box>
            <AddControllers open={open} handleClose={handleClose} AddUpdateController={AddUpdateController} />
        </Box>
    )
}

export default Controllers