import React, { useCallback, useEffect, useState } from "react";
import "../../MyPatients/MyPatients.css";
import "../../../Assets/Css/Responsive.css";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { GetApi, postApi } from "../../../Api/Api";
import Input from "../../../Components/InputComponent/Input";
import { LoadingButton } from "@mui/lab";


const Thresold = (props) => {
    const { patientId } = props;
    const [thresoldData, setThresoldData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);


    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
    })


    const onSubmit = async (data, event) => {
        event.preventDefault();
        const endpoint = `/upsertThreshold/${patientId}`

        const formattedData = thresoldData.map(thresold => ({
            sensor_name: thresold.sensor_name,
            threshold_value: data[thresold.sensor_name] || 0
        }));

        try {
            setSubmitLoading(true)
            const responseData = await postApi(endpoint, { threshold_data: formattedData });
            if (responseData?.status === 200) {
                AddUpdateThresold();
                toast.success(responseData?.data?.message);
                setSubmitLoading(false);
            } else {
                toast.error(responseData?.data?.error);
                setSubmitLoading(false);
            }
        } catch (error) {
            console.log('Error:', error);
            setSubmitLoading(false);
        }
    };


    //Thresold data
    const fetchThresoldData = useCallback(async (page, rowsPerPage) => {
        const endpoint = `/getThreshols/${patientId}`;
        try {
            const response = await GetApi(endpoint);
            setThresoldData(response.data.data);
            response.data.data.map((item) => {
                setValue(item.sensor_name, item.threshold_value);
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);


    useEffect(() => {
        fetchThresoldData();
    }, [fetchThresoldData]);


    const AddUpdateThresold = () => {
        fetchThresoldData();
    };
    if (loading) {
        return <CircularProgress style={{ margin: "20px", color: '#91D9B9' }} />;
    }
    return (
        <Box mt={3}>
            <Box className="main-card">
                <Box className="main-card-header">
                    <Typography component="h4" className="text-primery text--left">
                        Thresold
                    </Typography>
                </Box>
                <Box className="main-card-body">
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        {thresoldData?.map((thresold, index) => (
                            <Grid container key={index}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Typography className="text-primery text--left" style={{fontSize: "18px"}}>
                                        {thresold.sensor_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <Box className="form-group">
                                        <Input
                                            placeholder="add thresold value"
                                            type="text"
                                            name={thresold.sensor_name}
                                            register={register}
                                            validationRules={{
                                                required: 'Thresold Value is required',
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]+)?$/,
                                                    message: 'Invalid weight format',
                                                },
                                            }} />
                                        {errors.threshold_value && <p className="error-message">{errors.threshold_value.message}</p>}
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                        <Box className="btn-wrapper">
                            <LoadingButton type="submit" className="thres-loading-btn" variant="contained" loading={submitLoading} onClick={handleSubmit}>
                                Update Thresold
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Thresold
