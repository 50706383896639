import {
  Box,
  Paper,
  InputBase,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Typography,
  Drawer,
  InputLabel,
  Select,
  Popover
} from "@mui/material";
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from "react";
import "./Header.css";
import "../../Assets/Css/Responsive.css";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import user from "../../Assets/Images/Dashboard/user.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import logo from "./../../Assets/Images/Login/logo.svg";
import SideBar from "../SideBar/SideBar";
import { useSearch } from "../../Context/SearchContext";
import Profile from "../../Screens/Profile/Profile";
import { useTimeZone } from "../../Context/TimeZoneContext";
import { basePath } from "../../routes/Config";
import { useDevice } from "../../Context/DeviceContext";
import { GetApi, GetApiParam } from "../../Api/Api.js";
import AddPhysician from "../../Screens/Physician/Add physician/AddPhysician.js";
import AddPatients from "../../Screens/MyPatients/AddPatients/AddPatients.js";
import { usePatient } from "../../Context/PatientContext.js";
import ChangePassword from "../../Screens/Profile/ChangePassword.js";
import { faIR } from "@mui/x-date-pickers";


const labels = {
  "America/Los_Angeles": "PST (America / Los_Angeles)",
  "America/New_York": "EST(America / New_York)",
  "America/Chicago": "CST(America / Chicago)",
  "America/Denver": "MST(America / Denver)",
  "Asia/Kolkata": "IST(Asia / Kolkata)",
  "UTC": "UTC(UTC)"
};


const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const { searchQuery, setSearchQuery } = useSearch();
  const [openPhysician, setOpenPhysician] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isShowDeviceInfo, setIsShowDeviceInfo] = useState(false);
  const { selectedTimeZone, setSelectedTimeZone, timeZoneLabel, setTimeZoneLabel } = useTimeZone();
  const { selectedDevice, setSelectedDevice, setDeviceLabel } = useDevice()
  const { selectedPatient, setSelectedPatient } = usePatient()
  const [deviceAnchorEl, setDeviceAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState([]);
  const open = Boolean(anchorEl);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.id
  const location = useLocation();


  const handlePopoverOpen = (event, device) => {
    setDeviceAnchorEl(event.currentTarget);
    setPopoverContent(device.time || []);
  };

  const handlePopoverClose = () => {
    setDeviceAnchorEl(null);
    setPopoverContent([]);
  };

  const isPopoverOpen = Boolean(deviceAnchorEl);

  const fetchUserDetails = useCallback(async () => {
    const endpoint = '/user/'
    try {
      const response = await GetApiParam(endpoint, { id: userId })
      console.log('response', response)
      if (response.data.data[0]) {
        setUserData(response?.data?.data[0]);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const fetchDeviceName = useCallback(async () => {
    const endpoint = 'controllers';
    try {
      const responseData = await GetApi(endpoint);
      if (responseData.data.data) {
        setDeviceList(responseData.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const fetchPatientData = useCallback(async () => {
    const endpoint = `/getPairDevicePatient`;
    try {
      const response = await GetApi(endpoint);
      if (response.data.data) {
        if (response.data.data.length > 0) {
          setPatientData(response.data.data);
          setSelectedPatient(response.data.data[0]?.patient_id);
          fetchPatientDevice(response.data.data[0]?.patient_id);
        } else {
          setPatientData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    fetchDeviceName();
    fetchPatientData();
  }, [fetchDeviceName, fetchPatientData]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    window.history.replaceState({}, "", `${basePath}login`);
    navigate(`${basePath}login`, { state: { from: basePath } });
    // navigate("/PercyApp_Frontend/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setShowSidebar(newOpen);
  };

  const handleProfileOpen = () => {
    if (userDetails?.user_type) {
      if (userDetails?.user_type === "super_admin") {
        setOpenAdmin(true);
      } else if (userDetails?.user_type === "patient") {
        setOpenPatient(true);
      } else if (userDetails?.user_type === "physician") {
        setOpenPhysician(true);
      }
    }
    handleClose();
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
    handleClose();
  }

  const handleProfileClose = () => {
    setOpenPhysician(false);
    setOpenPatient(false);
    setOpenAdmin(false)
  };

  const handleChangePasswordClose = () => {
    setOpenChangePassword(false)
  };

  const handleChangeTimeZone = (event) => {
    setSelectedTimeZone(event.target.value);
  };

  const handleChangeDevice = (event) => {
    setSelectedDevice(deviceList.find((item) => item.device_id === event.target.value));
  };

  const handleChangePatient = (event) => {
    setSelectedPatient(event.target.value);
    fetchPatientDevice(event.target.value);
  };

  const fetchPatientDevice = useCallback(async (id) => {
    const endpoint = `/getPatientPairDevicesHistory/${id}`;
    try {
      const responseData = await GetApi(endpoint);
      if (responseData.data.data && responseData.data.data.length > 0) {
        setDeviceList(responseData.data.data);
        console.log("responseData.data.data[0]---", responseData.data.data[0]);
        setSelectedDevice(responseData.data.data[0])
      } else {
        setDeviceList([]);
        setSelectedDevice({})
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);


  useEffect(() => {
    setTimeZoneLabel(labels[selectedTimeZone])
    setDeviceLabel(deviceList[selectedDevice])
  }, [selectedTimeZone, selectedDevice])

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "0 0 6px 6px",
      marginTop: theme.spacing(1),
      minWidth: 200,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const validRoutes = [
    '/sensors-monitoring',
    '/event-monitoring',
    '/video-monitoring',
    '/video-monitoring/:id',
  ];

  const shouldDisplaySection = validRoutes.some(route => location.pathname.startsWith(route));

  return (
    <Box>
      <Box className="header-container">

        <Box className="left-section">
          {shouldDisplaySection && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box className="header-menu-toggle">
                <Box className="mobile-logo">
                  <Link to="#">
                    <img src={logo} alt="Logo" />
                  </Link>
                </Box>
                <Box className="toggle-btn-box">
                  <Button onClick={toggleDrawer(true)}>
                    <ReorderIcon />
                  </Button>
                  <Drawer open={showSidebar} onClose={toggleDrawer(false)}>
                    <SideBar />
                  </Drawer>
                </Box>
              </Box>
              <Box className="select-option">
                <InputLabel className="label-color">Select Patients</InputLabel>
                <Select
                  value={selectedPatient}
                  onChange={handleChangePatient}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Data Type' }}
                >
                  {patientData.map((patient) => (
                    <MenuItem key={patient.patient_id} value={patient.patient_id}>
                      {patient.patientData.patient_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {/* <Box className="select-option">
              <InputLabel className="label-color">Select Device</InputLabel>
              <Select
                value={selectedDevice && selectedDevice?.device_id ? selectedDevice?.device_id : ""}
                onChange={handleChangeDevice}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Data Type' }}
              >
                {deviceList.map((device, index) => (
                  <MenuItem key={index} value={device.device_id}>
                    {device.device_id}
                      <InfoIcon sx={{ml:1}} onClick={()=> setIsShowDeviceInfo(true)} onBlur={()=> setIsShowDeviceInfo(false)}/>
                      <Popover
                        open={isShowDeviceInfo}
                        onClose={()=>setIsShowDeviceInfo(false)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        {device.time && device.time.length > 0 && device.time.map((tm) => (
                          <>({format(tm.start_timestamp, 'MM/dd/yy - hh:mm a')}) - ({tm.end_timestamp ? format(tm.end_timestamp, 'MM/dd/yy - hh:mm a') : '-' }) <br /></>
                        ))}
                      </Popover>
                  </MenuItem>
                ))}
              </Select>
            </Box> */}
              <Box className="select-option">
                <InputLabel className="label-color">Select Device</InputLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Select
                    value={selectedDevice?.device_id || ""}
                    onChange={handleChangeDevice}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Select Data Type' }}

                  >
                    {deviceList.map((device, index) => (
                      <MenuItem key={index} value={device.device_id}>
                        {device.device_id}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton
                    onClick={(e) => handlePopoverOpen(e, selectedDevice)}
                  >
                    <InfoIcon sx={{ color: 'var(--primeryColor)' }} fontSize="small" />
                  </IconButton>
                  <Popover
                    open={isPopoverOpen}
                    anchorEl={deviceAnchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    className="header-menu"
                  >
                    <Box sx={{ p: 2 }}>
                      {popoverContent.length > 0 ? (
                        popoverContent.map((tm, idx) => (
                          <div key={idx} style={{ color: 'white' }} >
                            {selectedDevice?.device_id || ""}
                            <br />
                            ({format(new Date(tm.start_timestamp), 'MM/dd/yy - hh:mm a')}) - ({tm.end_timestamp ? format(new Date(tm.end_timestamp), 'MM/dd/yy - hh:mm a') : '-'})
                            <br />
                          </div>
                        ))
                      ) : (
                        <div>No time data available</div>
                      )}
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className="select-option">
                <InputLabel className="label-color">Select TimeZone</InputLabel>
                <Select
                  value={selectedTimeZone}
                  onChange={handleChangeTimeZone}
                  displayEmpty
                  inputProps={{ "aria-label": "Select Data Type" }}
                >

                  {Object.entries(labels).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          )}
        </Box>

        <Box className="right-section">
          <Box className="notification-box">
            <NotificationsIcon sx={{ color: "#fff" }} />
          </Box>
          <Box className="header-profile--wrapper">
            <Button
              id="demo-customized-button"
              className="h-profile--btn"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <img src={user} alt="" className="profile-image" />
              <Typography component="span">Dr.{userData.first_name}</Typography>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              className="header-menu"
              anchorEl={anchorEl}
              open={open}
            >
              <MenuItem onClick={handleProfileOpen} disableRipple>
                View Profile
              </MenuItem>

              <MenuItem onClick={handleChangePassword} disableRipple>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout} disableRipple>
                Logout
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
      </Box>
      <Profile open={openAdmin} handleClose={handleProfileClose} />
      <AddPatients
        open={openPatient}
        handleClose={handleProfileClose}
        patientId={userDetails?.patient_id}
        editValue={true}
      />
      <AddPhysician
        open={openPhysician}
        handleClose={handleProfileClose}
        physicianId={userDetails?.id}
        editValue={true}
      />
      <ChangePassword
        open={openChangePassword}
        handleClose={handleChangePasswordClose}
      />
    </Box>
  );
};

export default Header;
