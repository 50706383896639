import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  Box,
  Typography,
  CircularProgress,
  TablePagination
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { deleteApi } from "../../../Api/Api";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import toast from "react-hot-toast";


const GoalsTable = ({ goalData, AddUpdateGoal, loading, currentPage, setCurrentPage, paginationData, rowsPerPage, setRowsPerPage, setEditValue, editValue, setGoalId, goalId, setValue }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = (id, goal_name) => {
    setGoalId(id);
    setValue("goal_name",goal_name);
    setEditValue(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/deleteGoal/${goalId}`;
    try {
      const requestData = { is_deleted: true }

      const responseData = await deleteApi(endpoint, requestData);
      if (responseData.data) {
        AddUpdateGoal();
        toast.success(editValue ? "Goal Updated" : "Goal Deleted");
      } else {
        toast.error(editValue ? "Goal Updated Failed" : "Goal Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = (id) => {
    setGoalId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px", color: '#91D9B9' }} />;
  }

  //Pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); // Reset to first page when changing rows per page
  };

  return (
    <Box className="table-container">
      {goalData.length ?
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Goal Name</StyledTableCell>
                  <StyledTableCell>created user</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goalData?.map((goal, index) => (
                  <StyledTableRow
                    key={goal.id}
                    style={{
                      borderBottom:
                        index !== goalData?.length - 1
                          ? "1px solid #e0e0e0"
                          : "none",
                    }}
                  >
                    <StyledTableCell>{goal.goal_name}</StyledTableCell>
                    <StyledTableCell>{goal.created_user}</StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="row" gap={2} justifyContent="center">
                        <Button
                          variant="contained"
                          color="primary"
                          className="btn-primary"
                          onClick={() => handleOpen(goal.id, goal.goal_name)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="btn-delete"
                          onClick={() => handleDelete(goal.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={styledPagination}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={paginationData.totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
          />
        </> :
        <Box sx={{ mt: 4 }}>
          <Typography
            className="title" component="h5" color='#91D9B9'
          >
            No Record Found
          </Typography>
        </Box>
      }

      <ConfirmationDialog
        open={openDialog}
        content={`Are you really want to Delete this ?`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
    </Box>
  );
};

export default GoalsTable;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styledPagination = {
  color: "rgb(255,255, 255, 70%)",
  ' & .MuiTablePagination-actions .Mui-disabled': {
    color: "rgb(255,255, 255, 40%)"
  },
  '& .MuiTablePagination-selectIcon': {
    color: "rgb(255,255, 255, 70%)",
  }
}