import React from 'react'
import { Box, FormControl, Select, MenuItem} from '@mui/material';
import "./SelectComponent.css"


const SelectComponent = (props) => {
    const{name, age, handleChange} = props
  return (
    <Box className="select-option">
      <FormControl >
        <Select
          value={age? age: ''}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><span>{name}</span></MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectComponent