import React from 'react'
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Toaster } from 'react-hot-toast';

function GraphLayout() {
    const location = useLocation();
    return (
        <>
            <Box sx={{ backgroundColor: "#021A22" }}>
                <Box p={2} >
                    <Toaster position="top-right" reverseOrder={false} />
                    <Box className="right-container">
                        <Box className={"main-contant " + (location.pathname === '/my-profile' ? 'profile-page' : null)} >
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default GraphLayout