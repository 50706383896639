import React, { useCallback, useEffect, useState } from "react";
import "../../MyPatients/MyPatients.css";
import "../../../Assets/Css/Responsive.css";
import { Box, Stack, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { GetApi, postApi, updateApi } from "../../../Api/Api";
import Input from "../../../Components/InputComponent/Input";
import { LoadingButton } from "@mui/lab";
import GoalsTable from "./GoalsTable";


const Goals = (props) => {
  const { patientId } = props;
  const [goalData, setGoalData] = useState([]);
  const [editValue, setEditValue] = useState(false);
  const [goalId, setGoalId] = useState(null);
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({

  })

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const GoalName = data.goal_name.trim();
    const formatedData = {
      goal_name: GoalName,
    }

    const endpoint = editValue ? `/updateGoal/${goalId}` : `/addGoal/${patientId}`;
    const apiFunction = editValue ? updateApi : postApi;

    try {
      setSubmitLoading(true)
      const responseData = await apiFunction(endpoint, formatedData);
      setSubmitLoading(false);
      if (responseData?.data?.statusCode === 200) {
        toast.success(responseData?.data?.message);
        setSubmitLoading(false);
        reset();
        AddUpdateGoal();
        setEditValue(false);
        setGoalId(null);
      } else {
        toast.error(responseData?.data?.error);
        setSubmitLoading(false);
      }
    } catch (error) {
      console.log('Error:', error);
      setSubmitLoading(false);
    }
  };


  //Goal data
  const fetchGoalData = useCallback(async (page, rowsPerPage) => {
    const endpoint = `/getGoals/${patientId}?page=${page}&rowperpage=${rowsPerPage}`;
    try {
      const response = await GetApi(endpoint);
      setGoalData(response.data.data);
      setPaginationData(response.data.pagination)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGoalData(currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const AddUpdateGoal = () => {
    fetchGoalData(currentPage + 1, rowsPerPage);
  };

  return (
    <Box mt={3}>
      <Box className="main-card">
        <Box className="my-patients-topbar" mt={3}>
          <Box>
            <Typography className="title" component="h4">
              &nbsp;Goal
            </Typography>
          </Box>
          <Box className="right btn-font-small">
            <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
                  <Box className="form-group">
                    <Input
                      placeholder="Please Enter Goal"
                      type="text"
                      name="goal_name"
                      register={register}
                      validationRules={{
                        required: 'Goal Name is required',
                      }} />
                    {errors.goal_name && <p className="error-message">{errors.goal_name.message}</p>}
                  </Box>
                  <Box className="btn-wrapper">
                    <LoadingButton type="submit" className="goal-loading-btn" variant="contained" loading={submitLoading} onClick={handleSubmit}>
                      Save
                    </LoadingButton>
                  </Box>
                  &nbsp;
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box className="main-card-body">
          <GoalsTable
            goalData={goalData}
            AddUpdateGoal={AddUpdateGoal}
            loading={loading}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            currentPage={currentPage}
            paginationData={paginationData}
            editValue={editValue}
            setEditValue={setEditValue}
            goalId={goalId}
            setValue={setValue}
            setGoalId={setGoalId}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Goals
