import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../Context/AuthContext';
import { basePath } from './Config';

const PrivateRoutes = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      navigate(`${basePath}login`);
    }

    if (user && user.user_type && !roles.includes(user.user_type)) {
      navigate(`${basePath}unauthorized`);
    }

  }, [user, navigate, rest.path, roles]);

  return (
    <Component />
  );
};

export default PrivateRoutes;
