import React, { useEffect, useState } from "react";
import "./MyPatients.css";
import { Box, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";

import { GetApi } from "../../Api/Api";
import PatientTable from "./components/PatientTable";
import AddPatients from "./AddPatients/AddPatients";
import { Toaster } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../Context/SearchContext";
import { useUser } from "../../Context/UserContext";

const MyPatients = () => {
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paginationData, setPaginationData] = useState([]);
  const { searchQuery, setSearchQuery } = useSearch();
  const { selectedUser} = useUser()

  const fetchPatientData = async (page, rowsPerPage) => {
    const endpoint = `/patients?page=${page}&rowperpage=${rowsPerPage}`;
    try {
      const response = await GetApi(endpoint);
      setPatientData(response.data.data);
      setPaginationData(response.data.pagination)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientData(currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const AddUpdatePatient = () => {
    fetchPatientData(currentPage + 1, rowsPerPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Patients
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
            <Paper component="form" className="search-input">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="search"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon sx={{ color: "var(--whiteLight)" }} />
              </IconButton>
            </Paper>
            {selectedUser?.user_type !== "super_admin" &&
              <ButtonComponent
                buttonText="Add Patient"
                buttonVariant="contained"
                startIcon={<AddIcon />}
                buttonStyle={{ borderRadius: "30px" }}
                onClick={handleOpen}
              />
            }
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <PatientTable
          patientData={patientData}
          AddUpdatePatient={AddUpdatePatient}
          loading={loading}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          currentPage={currentPage}
          paginationData={paginationData}
        />
      </Box>
      <AddPatients
        open={open}
        handleClose={handleClose}
        AddUpdatePatient={AddUpdatePatient}
      />
    </Box>
  );
};

export default MyPatients;
