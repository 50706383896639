import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider } from '@mui/material';
import React from 'react';
import "./SideBar.css";
import "../../Assets/Css/Responsive.css"
import logo from "./../../Assets/Images/Login/logo.svg"
import { ReactComponent as DashboardIcon } from "./../../Assets/Images/Sidebar/dashboard-icon.svg"
import { ReactComponent as MyPatientsIcon } from "./../../Assets/Images/Sidebar/my-patients-icon.svg"
import { ReactComponent as GoalSetupIcon } from "./../../Assets/Images/Sidebar/goals-setup-icon.svg"
import { ReactComponent as SetupMonitoringIcon } from "./../../Assets/Images/Sidebar/setup-monitoring-icon.svg"
import { ReactComponent as EventMonitoringIcon } from "./../../Assets/Images/Sidebar/event-monitoring-icon.svg"
import { Link, useLocation } from 'react-router-dom';
import { basePath } from '../../routes/Config';

const patientMenu = [
    { icon: DashboardIcon, primary: "Dashboard", path: basePath },
    { icon: GoalSetupIcon, primary: "Goals Setup", path: "#" },
    { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "#" },
    { icon: EventMonitoringIcon, primary: "Sensor Monitoring", path: `${basePath}sensors-monitoring` },
    { icon: EventMonitoringIcon, primary: "Event Monitoring", path: `${basePath}event-monitoring` },
    { icon: EventMonitoringIcon, primary: "Graph Monitoring", path: `${basePath}graph-monitoring` },
    { icon: EventMonitoringIcon, primary: "Video Monitoring", path: `${basePath}video-monitoring` },
];

const physicianMenu = [
    { icon: DashboardIcon, primary: "Dashboard", path: basePath },
    { icon: GoalSetupIcon, primary: "Goals Setup", path: "#" },
    { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "#" },
    { icon: EventMonitoringIcon, primary: "Sensor Monitoring", path: `${basePath}sensors-monitoring` },
    { icon: EventMonitoringIcon, primary: "Event Monitoring", path: `${basePath}event-monitoring` },
    { icon: EventMonitoringIcon, primary: "Graph Monitoring", path: `${basePath}graph-monitoring` },
    { icon: EventMonitoringIcon, primary: "Video Monitoring", path: `${basePath}video-monitoring` },
    { icon: MyPatientsIcon, primary: "My Patients", path: `${basePath}my-patients` },
    { icon: EventMonitoringIcon, primary: "Controller", path: `${basePath}my-controllers` },
];

const AdminMenu = [
    { icon: DashboardIcon, primary: "Dashboard", path: basePath },
    { icon: GoalSetupIcon, primary: "Goals Setup", path: "#" },
    { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "#" },
    // { icon: EventMonitoringIcon, primary: "Monitoring", path: `${basePath}monitoring` },
    { icon: EventMonitoringIcon, primary: "Sensor Monitoring", path: `${basePath}sensors-monitoring` },
    { icon: EventMonitoringIcon, primary: "Event Monitoring", path: `${basePath}event-monitoring` },
    { icon: EventMonitoringIcon, primary: "Graph Monitoring", path: `${basePath}graph-monitoring` },
    { icon: EventMonitoringIcon, primary: "Video Monitoring", path: `${basePath}video-monitoring` },
    { icon: GoalSetupIcon, primary: "Hospitals", path: `${basePath}my-hospitals` },
    { icon: SetupMonitoringIcon, primary: "Physician", path: `${basePath}my-physician` },
    { icon: MyPatientsIcon, primary: "My Patients", path: `${basePath}my-patients` },
    { icon: EventMonitoringIcon, primary: "Super Admin", path: `${basePath}my-users` },
    { icon: EventMonitoringIcon, primary: "Controller", path: `${basePath}my-controllers` },
];


const SideBar = () => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user'))

    let menuItems;
    if (user && user?.user_type) {
        if (user?.user_type === "super_admin") {
            menuItems = AdminMenu
        } else if (user?.user_type === "patient") {
            menuItems = patientMenu
        } else if (user?.user_type === "physician") {
            menuItems = physicianMenu
        }
    } else {
        menuItems = AdminMenu
    }


    return (
        <Box className="sidebar-container">
            <Box className="logo-container">
                <img src={logo} alt='' />
            </Box>
            <Box className="sidebar-menu-container">
                <List>
                    {menuItems.map((menuItem, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding className='menu-div'>
                                <ListItemButton component={Link} to={menuItem.path} className={(location.pathname === menuItem.path ? 'active' : '')}>
                                    <ListItemIcon className={'sidebar-icon-box ' + (menuItem.path === basePath ? 'svg-stroke' : '')} >
                                        <menuItem.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.primary} className='menu-name' />
                                </ListItemButton>
                            </ListItem>
                            <Divider className='divider-color' />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default SideBar





