import React, { useState } from 'react'
import { Box, Button, Grid, Modal, Typography, TextField, Autocomplete, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import './AddPatients.css'

import { Controller, useForm } from 'react-hook-form'
import 'react-phone-number-input/style.css'
import Input from '../../../Components/InputComponent/Input'
import ButtonComponent from '../../../Components/ButtonComponent/ButtonComponent'
import { postApi } from '../../../Api/Api'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'

const AddTreatments = (props) => {
  const { handleClose, open, patientId, AddUpdateTreatment } = props
  const [loading, setLoading] = useState();
  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  
  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint =  `/addPatientTreatment`;
      const formData = {
      ...data,
      patient_id: patientId
    }

    try {
      setLoading(true)
      const responseData = await postApi(endpoint, formData);
      if(responseData?.status === 200){
        if (responseData.data.data) {
          reset();
          handleClose();
          toast.success(responseData?.data?.message);
          AddUpdateTreatment()
          setLoading(false)
        } 
      }else{
        toast.error(responseData?.data?.error);
        setLoading(false)
      }
    } catch (error) {
      console.log('Error:', error);
      setLoading(false)
    }
  };

  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Add Treatment
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                 
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Symptoms"
                        placeholder="Diabettes"
                        type="text"
                        name="symptoms"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'symptoms" is required',
                        }} />
                      {errors.symptoms && <p className="error-message">{errors.symptoms.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Prescription"
                        placeholder=""
                        type="text"
                        name="prescription"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'prescription is required',
                        }}
                      />
                      {errors.prescription && <p className="error-message">{errors.prescription.message}</p>}
                    </Box>
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                    <Box className="btn-wrapper">
                        <LoadingButton type="submit" className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                          Add Treatment
                        </LoadingButton>
                        {/* <ButtonComponent type="submit" buttonText={editValue ? 'Update Physician' : 'Add Physician'} buttonVariant="contained" /> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddTreatments