import React, { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { GetApi } from "../../Api/Api";


const DeviceTable = () => {
    const [streamingData, setStreamingData] = useState([])
    const [loading, setLoading] = useState(true);


    const fetchStreamingData = useCallback(async () => {
        const endpoint = '/ts_raw_message/getStreamingUnstreamingDevice'
        try {
            const response = await GetApi(endpoint);
            if (response.data.data) {
                setStreamingData(response.data.data)
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    },[])

    useEffect(() => {
        fetchStreamingData()
    }, [fetchStreamingData])


    if (loading) {
        return <CircularProgress style={{ margin: "20px", color: '#91D9B9' }} />;
      }

    return (
        <Box className="table-container">
            {streamingData.length ?
                <>
                    <TableContainer component={Paper}>
                        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Device Id</StyledTableCell>
                                    <StyledTableCell style={{ textAlign: 'left' }}>Last Message</StyledTableCell>
                                    <StyledTableCell style={{ textAlign: 'left' }}>Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {streamingData?.map((stream, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            {stream.Deviceid}
                                        </StyledTableCell>
                                        <StyledTableCell>{stream.time.split(".")[0]} UTC </StyledTableCell>
                                        <StyledTableCell classesomponent="th" style={{ textAlign: 'left', color: stream.status === "Streaming" ? 'green' : 'red' }}>
                                            {stream.status}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </>
                :
                <Box sx={{ mt: 4 }}>
                    <Typography
                        className="title" component="h5" color='#91D9B9'
                    >
                        No Record Found
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export default DeviceTable;



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#042835",
        color: "rgb(255,255, 255, 70%)",
        borderBottom: "1px solid #021A22",
        fontFamily: "Acumin Pro",
        fontWeight: 700,
        fontSize: 16,
        textAlign: "left"

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: "#03212b",
        color: "rgb(255,255, 255, 40%)",
        borderBottom: "1px solid #021A22",
        fontFamily: "Acumin Pro",
        // fontSize: 16,
        textAlign: "left",
        wordBreak: 'break-word',
        whiteSpace: 'normal'

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

