// AppRouter.js
import React, { Suspense } from 'react';
import { Routes, Route} from 'react-router-dom';
import Dashboard from '../Screens/Dashboard/Dashboard';
import MyPatients from '../Screens/MyPatients/MyPatients';
import Login from '../Screens/Login/Login';
import Layout from '../Layouts/Layout/Layout';
import PrivateRoutes from './PrivateRoutes';
import Hospitals from '../Screens/Hospital/Hospital';
import Physician from '../Screens/Physician/Physician';
import User from '../Screens/User/User';
import SetGoalComponent from '../Screens/SetGoalComponent/SetGoalComponent';
import SetupMonitoring from '../Screens/SetupMonitoring/SetupMonitoring';
import HospitalDetail from '../Screens/Hospital/HospitalsDetails';
import PhysiciansDetails from '../Screens/Physician/PhysicianDetails';
import UsersDetails from '../Screens/User/UserDetails';
import Controllers from '../Screens/Controller/Controller';
import ControllerDetail from '../Screens/Controller/ControllersDetails';
import ForgotPassword from '../Screens/Login/ForgotPassword';
import MypatientsDetails from '../Screens/MyPatients/MypatientsDetails';
import { AuthProvider } from '../Context/AuthContext';
import ResetPassword from '../Screens/Login/ResetPassword';
import CheckEmail from '../Screens/Login/CheckEmail';
import LoginRoutes from './LoginRoutes'
import VideoPlayer from '../Screens/VideoMonitoring/VideoPlayer';
import GraphLayout from '../Layouts/Layout/GraphLayout';
import { basePath } from './Config';
import GraphMonitoring from '../Screens/GraphsMonitoring/GraphsMonitoring';
const EventComponent = React.lazy(() => import('../Screens/EventMonitoring/EventManagement'));
const SensorComponent = React.lazy(() => import('../Screens/SensorsMonitoring/SensorMonitoring'));
const VideoComponent = React.lazy(() => import('../Screens/VideoMonitoring/VideoMonitoring'));
const monitorComponent = React.lazy(() => import('../Screens/SensorsMonitoring/Monitoring'));


// const adminRoutes = [
//   {
//     path: basePath, element: <Layout />, children: [
//       { path: 'my-hospitals', element: <PrivateRoutes component={Hospitals} roles={['super_admin']} /> },
//       { path: 'my-hospitals/:id', element: <PrivateRoutes component={HospitalDetail} roles={['super_admin']} /> },
//       { path: 'my-physician', element: <PrivateRoutes component={Physician} roles={['super_admin']} /> },
//       { path: 'my-physician/:id', element: <PrivateRoutes component={PhysiciansDetails} roles={['super_admin']} /> },
//       { path: 'my-users', element: <PrivateRoutes component={User} roles={['super_admin']} /> },
//       { path: 'my-users/:id', element: <PrivateRoutes component={UsersDetails} roles={['super_admin']} /> },
//       { path: 'my-controllers', element: <PrivateRoutes component={Controllers} roles={['super_admin']} /> },
//     ]
//   },
// ];

const commonRoutes = [
  {
    path: basePath, element: <Layout />, children: [
      { index: true, element: <PrivateRoutes component={Dashboard} roles={['super_admin', 'patient', 'physician']} /> },
      
      { path: 'my-hospitals', element: <PrivateRoutes component={Hospitals} roles={['super_admin']} /> },
      { path: 'my-hospitals/:id', element: <PrivateRoutes component={HospitalDetail} roles={['super_admin']} /> },
      
      { path: 'my-users', element: <PrivateRoutes component={User} roles={['super_admin']} /> },
      { path: 'my-users/:id', element: <PrivateRoutes component={UsersDetails} roles={['super_admin']} /> },

      { path: 'my-physician', element: <PrivateRoutes component={Physician} roles={['super_admin']} /> },
      { path: 'my-physician/:id', element: <PrivateRoutes component={PhysiciansDetails} roles={['super_admin']} /> },

      { path: 'my-patients', element: <PrivateRoutes component={MyPatients} roles={['super_admin', 'physician']} /> },
      { path: "my-patients/:id", element: <PrivateRoutes component={MypatientsDetails} roles={['super_admin', 'physician']} /> },
      
      // { path: 'prescription', element: <PrivateRoutes component={Prescription} roles={['super_admin', 'physician']} />},
      // { path: 'prescription-details', element: <PrivateRoutes component={PrescriptionDetails} roles={['super_admin', 'physician']} />},

      { path: 'my-controllers', element: <PrivateRoutes component={Controllers} roles={['super_admin', 'patient', 'physician']} /> },
      { path: 'my-controllers/:id', element: <PrivateRoutes component={ControllerDetail} roles={['super_admin', 'patient', 'physician']} /> },
      
      { path: 'goals-setup', element: <PrivateRoutes component={SetGoalComponent} roles={['super_admin', 'patient', 'physician']} /> },
      { path: 'setup-monitoring', element: <PrivateRoutes component={SetupMonitoring} roles={['super_admin', 'patient', 'physician']} /> },
     
      // { path: "monitoring", element: <Suspense><PrivateRoutes component={monitorComponent} roles={['super_admin', 'patient', 'physician']} /> </Suspense>},
      { path: "sensors-monitoring", element: <Suspense><PrivateRoutes component={SensorComponent} roles={['super_admin', 'patient', 'physician']} /> </Suspense>},
      { path: "event-monitoring", element: <Suspense><PrivateRoutes component={EventComponent} roles={['super_admin', 'patient', 'physician']} /> </Suspense>},
      
      { path: "video-monitoring", element: <Suspense><PrivateRoutes component={VideoComponent} roles={['super_admin', 'patient', 'physician']} /> </Suspense>},
      { path: 'video-monitoring/:id', element: <PrivateRoutes component={VideoPlayer} roles={['super_admin', 'patient', 'physician']} />},
     
      // { path: "my-profile", element: <PrivateRoutes component={Profile} roles={['super_admin', 'physician']} /> },
      // { path: "change-password", element: <PrivateRoutes component={ChangePassword} roles={['super_admin', 'physician']} /> },
    ]
  },
  {
    path: basePath, element: <GraphLayout />, children: [
      { path: "graph-monitoring", element: <PrivateRoutes component={GraphMonitoring} roles={['super_admin','patient', 'physician']} />},
      // { path: "optimize", element: <PrivateRoutes component={OptimizeCode} roles={['super_admin']} />},
    ]
  },
];

const AppRouter = () => {
  return (
    <AuthProvider>
      <Routes>
        {commonRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          >
            {Array.isArray(route.children) && route.children.map((childRoute, childIndex) => (
              <Route
                key={childIndex}
                {...childRoute}
              />
            ))}
          </Route>
        ))}

        {/* {adminRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          >
            {Array.isArray(route.children) && route.children.map((childRoute, childIndex) => (
              <Route
                key={childIndex}
                {...childRoute}
              />
            ))}
          </Route>
        ))} */}

        <Route path={`${basePath}login`} element={<LoginRoutes component={Login} /> }/>
        <Route path={`${basePath}forget-password`} element={<LoginRoutes component={ForgotPassword} />} />
        <Route path={`${basePath}reset-password/:token`} element={<LoginRoutes component={ResetPassword}/>} />
        <Route path={`${basePath}email-link` }element={<LoginRoutes component={CheckEmail}/>} />
      
      </Routes>
    </AuthProvider>
  );
};

export default AppRouter;
