

// HospitalDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetApiParam } from '../../Api/Api';
import { Box, CircularProgress } from '@mui/material';
import PhysicianCard from './components/PhysicianCard';

const PhysiciansDetails = () => {
  const { id } = useParams();
  const [physiciansDetails, setPhysiciansDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPhysiciansDetails = async () => {
      const endpoint = '/physician/';
      try {
        const response = await GetApiParam(endpoint, { id: id });
        setPhysiciansDetails(response.data.data[0]);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchPhysiciansDetails();
  }, [id]);

  if (loading) {
    return <CircularProgress style={{ margin: '20px' }} />;
  }

  return (
    <Box>
      Hello
      <PhysicianCard PhysiciansDetails={physiciansDetails} />
    </Box>
  );
};

export default PhysiciansDetails;
