import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import "./Dashboard.css"
import "../../Assets/Css/Responsive.css"
import DashboardCard from '../../Components/DashboardComponents/DashboardCard/DashboardCard'
import mypatients from "../../Assets/Images/Dashboard/my-patients.png"
import patientsbaseline from "../../Assets/Images/Dashboard/patients-baseline.png"
import patientsmaintenance from "../../Assets/Images/Dashboard/patients-maintenance.png"
import patientstreatment from "../../Assets/Images/Dashboard/patients-treatment.png"
import graph from "../../Assets/Images/Dashboard/graph.png"
import DeviceTable from './DeviceTable'


const Dashboard = () => {

    return (
        <Box>
            <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard icon={mypatients} title="Total Physician" count="02" customStyle={{ background: '#FFA6C7' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Total Patient" icon={patientsbaseline} count="04" customStyle={{ background: '#91D9B9' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Total Sensor" icon={patientsmaintenance} count="08" customStyle={{ background: '#FFC548' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Patients in Maintenance " icon={patientstreatment} count="01" customStyle={{ background: '#FA8352' }} />
                    </Grid>
                </Grid>
            </Box>
            <Box className="dash-graph-container" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} xl={8}>
                        <Box className="left-section">
                            <Typography className='heading' component="h4">Device Status</Typography>
                            <Box className="graph-img" mt={1}>
                                {/* <img src={graph} alt='' /> */}
                                <DeviceTable/>
                            </Box>  
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4} >
                        <Box className="right-section">
                            <Typography className='heading' component="h4">Controller status</Typography>
                        </Box>
                        <Box mt={1} className="controller-status-card">
                            <Box className="cont-card">
                                <Typography className='total-assig-contoller' component="h4">Total assigned
                                    controller</Typography>
                                <Typography className='count' component="h2">01</Typography>
                            </Box>
                            <Box className="cont-card" mt={3}>
                                <Typography className='total-assig-contoller' component="h4">Total unassigned
                                    controller</Typography>
                                <Typography className='count' component="h2">03</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Dashboard