
import axios from 'axios';
import { GetApi } from './Api';
import Cookies from 'js-cookie';

// const REACT_APP_BACKEND_URL = "http://52.87.56.163/stagebck/api"
// base url is passed inside create
const axiosInstance = axios.create(
  { baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers:{
    "X-Requested-With" : "XMLHttpRequest"
  }
}
);

// Interceptor for outgoing requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    // const token = Cookies.get('token');
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Interceptor for incoming responses
axiosInstance.interceptors.response.use(
  (response) => {
    // You can also perform checks or transformations here
    if (response?.data?.code === 401) {
      // Redirect the user to the login page or refresh the token
      localStorage.removeItem("token")
      window.location.href = '/login';
    }
    return response;
  },
  async (error) => {
    console.log('error', error)
       // You can also handle other error codes as needed
    if (error?.response?.data?.code === 401) {
      // Redirect the user to the login page or refresh the token
      localStorage.removeItem("token")
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;