import { Box, Typography } from "@mui/material";
import React from "react";
// import { ReactComponent as PreSleepRoutine } from "./../../Assets/Images/Dashboard/Pre-Sleep-Routine.svg";

import TableComponent from "../../Components/TableComponent/TableComponent";
// import EventCardComponent from "../../Components/EventCardComponent/EventCardComponent";
// import CircularProgressBar from "../../Components/CircularProgressBar/CircularProgressiveBar";

const SetupMonitoring = () => {
  return (
    <Box className="" mt={3}>
      <Box className="title-with-border text-primery text--left">
        <Typography component="h4" className="title">
          Goal Setup
        </Typography>
      </Box>
      <Box mt={2}>
        <TableComponent />
      </Box>
      {/* <Box mt={2}>
        <EventCardComponent
          icon={PreSleepRoutine}
          title="Pre-Sleep Routine"
          duration="DURATION"
          progress={PreSleepRoutine}
        />
      </Box> */}
     
    </Box>
  );
};

export default SetupMonitoring;
