import { Box, Typography } from '@mui/material'
import React from 'react'
import "./MypatientsCard.css"
import user from "../../../Assets/Images/Dashboard/user.png"


const MyPatientsDetailsCard = (props) => {
    const{title, patient_name, patient_nick_name, patient_code, reference_name, age, gender, weight, height, phone_no, email_id, address, disease} = props;
    return (
        <Box className="my-patients-card-container">
            <Box className="patents-details-wrapper">
                <Box className="top-view">
                    <Box>
                        <img src={user} alt='' />
                    </Box>
                    <Box ml={2}>
                        <Typography className='user-name' component="h5">{patient_name}</Typography>
                        <Typography className='address'>{address}</Typography>
                        <Typography className='address'>Phone: {phone_no}</Typography>
                    </Box>
                </Box>
                <Box className="user-info-box">
                    <Box>
                        <Typography className='title'>Age</Typography>
                        <Typography className='sub-title' component="h6">{age}y</Typography>
                    </Box>
                    <Box className="border-dotted"></Box>
                    <Box>
                        <Typography className='title'>Gender</Typography>
                        <Typography className='sub-title' component="h6">{gender}</Typography>
                    </Box>
                    <Box className="border-dotted"></Box>
                    <Box>
                        <Typography className='title'>Weight</Typography>
                        <Typography className='sub-title' component="h6">{weight}kg</Typography>
                    </Box>
                    <Box className="border-dotted"></Box>
                    <Box>
                        <Typography className='title'>Height</Typography>
                        <Typography className='sub-title' component="h6">{height}cm</Typography>
                    </Box>
                </Box>
                <Box mt={3} className="goal-set-box">
                    <Typography className='title'>Diseases</Typography>
                    <Typography className='sub-title'>{disease}</Typography>
                </Box>
                <Box className="card-footer">
                    <Box>
                        <Typography className='title' >Goal Set</Typography>
                        <Typography className='sub-title'>Pre-Sleep Routine | Out of Bed | arent Interaction</Typography>
                    </Box>
                    
                </Box>
            </Box>
        </Box>
    )
}

export default MyPatientsDetailsCard