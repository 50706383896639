import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../Context/AuthContext';
import { basePath } from './Config';

const LoginRoutes = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const token = localStorage.getItem('token');
    
  
    if (token) {
      navigate(basePath);
    }
  }, [user, navigate]);

  return (
    <Component />
  );
};

export default LoginRoutes;
