
import React, { useCallback, useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import WeeklyProgressGraph from "../../Assets/Images/Dashboard/weekly-progress-graph.png";
import AddTreatments from "./AddPatients/AddTreatments";
// import Card from '@mui/joy/Card';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Box,
  Stack,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useParams } from "react-router-dom";
import { GetApiParam, postApi } from "../../Api/Api";
import MyPatientsDetailsCard from "../../Components/DashboardComponents/MyPatientsCard/MyPatientsDetailsCard";
import moment from "moment/moment";
import toast from "react-hot-toast";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Goals from "./Goal/Goals";
import Thresold from "./Thresold/Thresold";


const PatientsDetails = () => {
  const [open, setOpen] = useState(false);
  const [patientDetails, setPatientDetails] = useState([])
  const [activeTreatmentDetails, setActiveTreatmentDetails] = useState([])
  const [previousTreatmentDetails, setPreviousTreatmentDetails] = useState([])
  const [tabValue, setTabValue] = useState("active");
  const [titleTabValue, setTitleTabValue] = useState("patientDetails");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTitleChangeTab = (event, newValue) => {
    setTitleTabValue(newValue);
  };

  //Patient Details
  const fetchPatientDetails = useCallback(async () => {
    const endpoint = '/patient/'
    try {
      const response = await GetApiParam(endpoint, { id: id })
      setPatientDetails(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching patient details:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPatientDetails();
  }, [fetchPatientDetails]);

  /**TreatmentDetails */
  const fetchActiveTreatmentDetails = async () => {
    const endpoint = `/getLatestPatientThreatment/`
    try {
      const response = await GetApiParam(endpoint, { patient_id: id })
      if (response.data.data.length > 0) setTabValue('active');
      else setTabValue('previous');
      setActiveTreatmentDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Treatment details:', error);
      setLoading(false);
    }
  };

  /**Previous TreatmentDetails */
  const fetchPreviousTreatmentDetails = async () => {
    const endpoint = `/getPreviousPatientThreatment/`
    try {
      const response = await GetApiParam(endpoint, { patient_id: id })
      setPreviousTreatmentDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Treatment details:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchActiveTreatmentDetails();
    fetchPreviousTreatmentDetails();
  }, [id]);

  const AddUpdateTreatment = () => {
    fetchPatientDetails();
    fetchActiveTreatmentDetails();
    fetchPreviousTreatmentDetails();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (activeTreatmentDetails.length !== 0) {
      setTabValue("active");
    }
  }, [activeTreatmentDetails]);

  const handleEndTreatment = async () => {
    const endpoint = `/endPatientThreatment/`
    const requestData = {
      patient_id: id,
      ptm_id: activeTreatmentDetails[0].ptm_id
    };
    try {
      const responseData = await postApi(endpoint, requestData);
      if (responseData?.status === 200) {
        if (responseData.data) {
          toast.success(responseData.data.message);
          fetchPatientDetails();
          fetchActiveTreatmentDetails();
          fetchPreviousTreatmentDetails();
        }
      } else {
        toast.error(responseData?.data?.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px", color: '#91D9B9' }} />;
  }

  return (
    <Box>
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Box>
            <Tabs
              value={titleTabValue}
              onChange={handleTitleChangeTab}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#91D9B9"
                }
              }}
              aria-label="Treatment tabs"
            >
              <Tab
                value="patientDetails"
                label="Patient Details"
                sx={{
                  color: titleTabValue === "patientDetails" ? "#91D9B9 !important" : "rgba(255, 255, 255, 0.5) !important"
                }}
              />
              <Tab
                value="threesold"
                label="Threesold"
                sx={{
                  color: titleTabValue === "threesold" ? "#91D9B9 !important" : "rgba(255, 255, 255, 0.5) !important"
                }}
              />
              <Tab
                value="goal"
                label="Goal"
                sx={{
                  color: titleTabValue === "goal" ? "#91D9B9 !important" : "rgba(255, 255, 255, 0.5) !important"
                }}
              />
            </Tabs>
          </Box>
        </Box>

        <Box className="right btn-font-small">
          {patientDetails.isTreatmentActive ?
            <Stack direction="row" gap={2}>
              <ButtonComponent
                buttonText="End Treatment"
                buttonVariant="outline"
                onClick={handleEndTreatment}
                buttonStyle={{
                  borderRadius: "30px",
                  color: "#ffffff",
                  padding: "5px 10px",
                }}
                buttonClass="outline-btn only-icon-btn"
              />
              <ButtonComponent
                buttonText="Treatment"
                buttonVariant="outline"
                buttonStyle={{
                  borderRadius: "30px",
                  color: "#ffffff",
                  padding: "5px 10px",
                }}
                buttonClass="outline-btn only-icon-btn"
                onClick={handleOpen}
              />
            </Stack> :
            <ButtonComponent
              buttonText="Start Treatment"
              buttonVariant="outline"
              buttonStyle={{
                borderRadius: "30px",
                color: "#ffffff",
                padding: "5px 10px",
              }}
              buttonClass="outline-btn only-icon-btn"
              onClick={handleOpen}
            />
          }
        </Box>
      </Box>

      {titleTabValue === "patientDetails" && (
        <>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={5} lg={4}>
                <MyPatientsDetailsCard {...patientDetails} />
              </Grid>
              <Grid item sm={12} xs={12} md={7} lg={8}>
                <Box className="main-card">
                  <Box className="main-card-header">
                    <Typography component="h4" className="text-primery text--left">
                      Weekly progress
                    </Typography>
                  </Box>
                  <Box className="main-card-body">
                    <img src={WeeklyProgressGraph} alt="" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="my-patients-topbar" mt={3}>
            <Box>
              <Typography className="title" component="h4">
                Treatment Histroy
              </Typography>
            </Box>
          </Box>
          {previousTreatmentDetails.length === 0 && activeTreatmentDetails.length === 0 ?
            <Box sx={{ mt: 4 }}>
              <Typography
                className="title" component="h5" color='#91D9B9'
              >
                No Record Found
              </Typography>
            </Box>
            :
            <>
              {previousTreatmentDetails.length !== 0 &&
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#91D9B9"
                      }
                    }}
                    aria-label="Treatment tabs"
                  >
                    {activeTreatmentDetails.length !== 0 &&
                      <Tab
                        value="active"
                        label="Active"
                        sx={{
                          color: tabValue === "active" ? "#91D9B9 !important" : "rgba(255, 255, 255, 0.5) !important"
                        }}
                      />
                    }
                    <Tab
                      value="previous"
                      label="Previous"
                      sx={{
                        color: tabValue === "previous" ? "#91D9B9 !important" : "rgba(255, 255, 255, 0.5) !important"
                      }}
                    />
                  </Tabs>
                </Box>
              }


              {/* Show Previous treatments when active tab is selected */}
              {previousTreatmentDetails.length !== 0 &&
                tabValue === 'previous' && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <StyledTableRow sx={{ display: 'table', width: '100%' }}>
                          <StyledTableCell>Start Treatment</StyledTableCell>
                          <StyledTableCell>End Treatment</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {/* Map through previous treatments and create an accordion panel for each */}
                        {previousTreatmentDetails?.map((treatment, index) => (
                          <TableRow>
                            <Accordion sx={styledAcordian} key={index} expanded={expanded === index} onChange={handleChange(index)}>
                              <AccordionSummary
                                sx={styledAcordianSummary}
                                expandIcon={<ExpandMoreIcon sx={{ color: "rgb(255,255, 255, 40%)" }} />}
                              >
                                <TableCell sx={previousTableCell}>
                                  {moment(treatment.start_date).format('MMMM Do YY, h:mm:ss')}
                                </TableCell>
                                <TableCell sx={previousTableCell}>
                                  {moment(treatment.end_date).format('MMMM Do YY, h:mm:ss')}
                                </TableCell>
                              </AccordionSummary>
                              <AccordionDetails sx={styledAcordianSummary}>
                                {/* Table to display treatment details */}
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <StyledTableRow>
                                        <StyledTableCell>Visited Time</StyledTableCell>
                                        <StyledTableCell>Symptom</StyledTableCell>
                                        <StyledTableCell>Prescription</StyledTableCell>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      {treatment.treatmentData.map((detail, idx) => (
                                        <StyledTableRow key={idx}>
                                          <StyledTableCell>{moment(detail.visited_date).format('MMMM Do YY, h:mm:ss')}</StyledTableCell>
                                          <StyledTableCell>{detail.symptoms}</StyledTableCell>
                                          <StyledTableCell>{detail.prescription}</StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
              }

              {/* Show active treatments when active tab is selected */}
              {activeTreatmentDetails.length !== 0 && tabValue === 'active' && (
                <TableContainer component={Paper}>
                  <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Visited Time</StyledTableCell>
                        <StyledTableCell>Symptom</StyledTableCell>
                        <StyledTableCell>Prescription</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {activeTreatmentDetails?.map((treatment, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {moment(treatment.visited_date).format('MMMM Do YY, h:mm:ss')}
                          </StyledTableCell>
                          <StyledTableCell>{treatment.symptoms}</StyledTableCell>
                          <StyledTableCell>{treatment.prescription}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          }
        </>
      )}

      {
        titleTabValue === 'threesold' && (
          <Thresold patientId={id} />
        )
      }
      {
        titleTabValue === 'goal' && (
          <Goals patientId={id} />
        )
      }

      <AddTreatments
        open={open}
        handleClose={handleClose}
        patientId={id}
        AddUpdateTreatment={AddUpdateTreatment}
      />
    </Box >
  );
};

export default PatientsDetails;

const styledAcordian = {
  backgroundColor: "#042835",
  margin: '0px !important'
}
const styledAcordianSummary = {
  backgroundColor: "#03212b",
  '& .MuiAccordionSummary-content': {
    display: 'table',
  },
}

const previousTableCell = {
  color: "rgb(255,255, 255, 40%)",
  borderBottom: "1px solid #021A22"
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

