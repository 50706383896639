import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { GetApi } from '../Api/Api';

const PatientContext = createContext();

export const PatientProvider = ({ children }) => {
  const [selectedPatient, setSelectedPatient] = useState("");

  return (
    <PatientContext.Provider value={{ selectedPatient, setSelectedPatient }}>
      {children} {/* Render children only when patientData has been fetched */}
    </PatientContext.Provider>
  );
};


export const usePatient = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatient must be used within a PatientProvider");
  }
  return context;
};
