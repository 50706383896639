import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, Modal, Typography } from '@mui/material'
import './AddHospitals.css'
import '../../../Assets/Css/Responsive.css'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../../Components/InputComponent/Input'
import { GetApiParam, postApi, updateApi } from '../../../Api/Api'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import PhoneInput,{ formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

const AddHospitals = (props) => {
  const { handleClose, open, editValue, hospitalId, AddUpdateHospital } = props;
  const [loading, setLoading] = useState();

  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (editValue) {

      const fetchHospitalDetails = async () => {
        const endpoint = '/hospital/'

        try {
          const response = await GetApiParam(endpoint, { id: hospitalId })
          const hospitalDetails = response.data.data[0];
          setValue('hospital_name', hospitalDetails.hospital_name);
          setValue('phone_no', hospitalDetails.phone_no);
          setValue('email_id', hospitalDetails.email_id);
          setValue('address', hospitalDetails.address);
        } catch (error) {
          console.error('Error fetching hospital details:', error);
        }
      };
      fetchHospitalDetails();
    }
  }, [editValue, hospitalId, setValue]);

  useEffect(() => {
    if (!editValue && open) {
      reset()
    }
  }, [editValue, open, reset]);


  const onSubmit = async (data, event) => {
    event.preventDefault();
    const hospitalName = data.hospital_name.trim();
    const hospitalAddress = data.address.trim();

    const formatedData = {
      ...data,
      hospital_name: hospitalName,
      address: hospitalAddress
    }

    const endpoint = editValue ? `/hospital/${hospitalId}` : '/hospital/';
    const apiFunction = editValue ? updateApi : postApi;

    try {
      setLoading(true)
      const responseData = await apiFunction(endpoint, editValue ? { ...formatedData, id: hospitalId } : formatedData);
      if (responseData?.status === 200) {
        reset();
        handleClose();
        toast.success(responseData?.data?.message);
        AddUpdateHospital()
        setLoading(false)
      } else {
        toast.error(responseData?.data?.error);
        setLoading(false)
      }
    } catch (error) {
      console.log('Error:', error);
      setLoading(false)
    }
  };

  const NameValidation = (value) => {
    return !value.trim() || value.trim().length === 0 ? 'First Name field is required' : true;
  };

  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Hospital
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Hospital Name"
                        placeholder="Apollo"
                        type="text"
                        name="hospital_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Hospital Name is required',
                          pattern: {
                            value: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
                            message: 'Invalid Middle Name',
                          },
                          maxLength: {
                            value: 512,
                            message: 'Maximum 256 characters are allowed.'
                          },
                          validate: NameValidation
                        }} />
                      {errors.hospital_name && <p className="error-message">{errors.hospital_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">
                          Phone Number <span style={{ display: 'inline-block', color: 'red' }}>*</span>
                        </InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const phoneNumber = formatPhoneNumber(value);
                                if (!phoneNumber) return 'Please provide valid phone number';
                                if (!isValidPhoneNumber(value)) return 'Invalid phone number';
                                return true;
                              }
                            }}
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country="US"
                                placeholder="Enter phone number"
                                inputStyle={{ width: '100%' }}
                                error={errors.phone_no ? true : false}
                              />
                            )}
                          />
                        </FormControl>
                        {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Email ID is required',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Please enter a valid email address.',
                          },
                        }}
                      />
                      {errors.email_id && <p className="error-message">{errors.email_id.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Address"
                        placeholder="Pitts"
                        type="text"
                        name="address"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Address is required',
                          maxLength: {
                            value: 512,
                            message: 'Maximum 256 characters are allowed.'
                          },
                          validate: NameValidation
                        }}
                      />
                      {errors.address && <p className="error-message">{errors.address.message}</p>}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-wrapper">
                        <LoadingButton type="submit" className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                          {editValue ? 'Update Hospital' : 'Add Hospital'}
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddHospitals

const stylePhoneInput = {
  '& . PhoneInputInput': {
    color: "rgb(255,255, 255, 40%)"
  }
}