import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material'
import './AddUser.css'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../../Components/InputComponent/Input'
import { GetApiParam, postApi, updateApi } from '../../../Api/Api'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const AddUser = (props) => {
  const { handleClose, open, editValue, userId, AddUpdateUser } = props;
  const [loading, setLoading] = useState();
  const nameValidationPattern = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;

  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (editValue) {
      const fetchUserDetails = async () => {
        const endpoint = '/user/'

        try {
          const response = await GetApiParam(endpoint, { id: userId })
          const userDetails = response.data.data[0];
          setValue('title', userDetails.title);
          setValue('first_name', userDetails.first_name);
          setValue('middle_name', userDetails.middle_name);
          setValue('last_name', userDetails.last_name);
          setValue('phone_no', userDetails.phone_no);
          setValue('email_id', userDetails.email_id);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };

      fetchUserDetails();
    }
  }, [editValue, setValue, userId]);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    const UserFirstName = data.first_name.trim();
    const UserMiddleName = data.middle_name.trim();
    const UserLastName = data.last_name.trim();

    const formatedData = {
      ...data,
      first_name: UserFirstName,
      middle_name: UserMiddleName,
      last_name: UserLastName,
    }
    const endpoint = editValue ? `/user/${userId}` : '/user/';
    const apiFunction = editValue ? updateApi : postApi;

    try {
      setLoading(true)
      const responseData = await apiFunction(endpoint, editValue ? { ...formatedData, id: userId } : formatedData);

      if (responseData?.status === 200) {
        reset();
        handleClose();
        toast.success(responseData?.data?.message);
        AddUpdateUser()
        setLoading(false);
      } else {
        toast.error(responseData?.data?.error);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error:', error);
      setLoading(false);
    }
  };

  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                User
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group select-form-group">
                      <FormControl fullWidth>
                        <InputLabel shrink className='input-label'>Title</InputLabel>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              displayEmpty
                              onChange={(e) => {
                                setValue('title', e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <MenuItem value="" disabled><span sx={{ color: "rgb(255,255, 255, 10%)" }}>Select Title</span></MenuItem>
                              <MenuItem value="Mr">Mr.</MenuItem>
                              <MenuItem value="Ms">Miss</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="First Name"
                        placeholder="Lindsey"
                        type="text"
                        name="first_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'First Name is required',
                          pattern: {
                            value: nameValidationPattern,
                            message: 'Invalid Last Name'
                          }
                        }} />
                      {errors.first_name && <p className="error-message">{errors.first_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Middle Name"
                        placeholder="Daniel"
                        type="text"
                        name="middle_name"
                        register={register}
                        validationRules={{
                          pattern: {
                            value: nameValidationPattern,
                            message: 'Invalid Last Name'
                          }
                        }}
                      />
                      {errors.middle_name && <p className="error-message">{errors.middle_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Last Name"
                        placeholder="Thomas"
                        type="text"
                        name="last_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Last Name is required',
                          pattern: {
                            value: nameValidationPattern,
                            message: 'Invalid Last Name'
                          }
                        }} />
                      {errors.last_name && <p className="error-message">{errors.last_name.message}</p>}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">Phone Number</InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country={'us'}
                                label="Phone number"
                                placeholder="Phone number"
                              />
                            )}
                          />
                        </FormControl>
                        {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                      </Box>
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">
                        Phone Number <span style={{ display:'inline-block', color: 'red' }}>*</span>
                        </InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const phoneNumber = formatPhoneNumber(value);
                                if (!phoneNumber) return 'Please provide valid phone number';
                                if (!isValidPhoneNumber(value)) return 'Invalid phone number';
                                return true;
                              }
                            }}
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country="US"
                                placeholder="Enter phone number"
                                inputStyle={{ width: '100%' }}
                                error={errors.phone_no ? true : false}
                              />
                            )}
                          />
                        </FormControl>
                        {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Email is required',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Please enter a valid email address'
                          }
                        }}
                      />
                      {errors.email_id && <p className="error-message">{errors.email_id.message}</p>}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-wrapper">
                        <LoadingButton type="submit" className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                          {editValue ? 'Update User' : 'Add User'}
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddUser