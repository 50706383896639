import React from 'react';
import { Box,  FormControl, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import "./SetGoalComponet.css"
import SelectComponent from '../../Components/SelectComponent/SelectComponent';

const SetGoalComponent = () => {
  
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&:has([readonly]) ": {
              "& .MuiInputLabel-outlined": {
                color: "#cecece",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#cecece",
              },
            },
          },
        },
      },
    },
  });





  // const option = [
  //   { label: '1' },
  //   { label: '2' },
  //   { label: '3' },
  //   { label: '4' },
  //   { label: '5' },
  // ];


  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const goals = [
    {label:"pro-sleep Routine"},
    {label:"Out of Bed"},
    {label:"Sleep Period"},
    {label:"Vocalizations"},
    {label:"Parent Interaction"},
    {label:"Environment"},
    {label:"Out of bed"},
    {label:"Sleep At Night"},
    {label:"Sleep onset delay"},
    {label:"Night Wakings"},
    {label:"Stereotypy"},
    {label:"Naptime Wakings"},
    {label:"Sitting or Standing"},
    {label:"Naptime Prep Duration"},
    {label:"Baby Leaves The Nursery"},
    {label:"Nap Time Duration"},
    {label:"Nighttime bedtime prep duration"},
  ]

  return (
      <Box mt={3}>
          <Box className="title-with-border text-primery text--left">
              <Typography component="h4" className='title'>Goal Setup</Typography>
          </Box>
          <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={8} xl={7}>
                      

                    {goals.map((goal, index)=>(
                      <Grid container key={index} spacing={2}>
                        <Grid item sm={8} xs={12} md={8} lg={8} xl={8}>
                            <Box className="">
                                <Box className="readonly-intup-box">
                                    <FormControl variant="standard">
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                variant="outlined"
                                                defaultValue={goal.label}
                                                inputProps={{ readOnly: true }}
                                            />
                                          </ThemeProvider>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={12} md={4} xl={4}>
                            <Box className="form-group">
                              <Box className="cstm-select-option">

                                <SelectComponent name="Select Value" age={age} handleChange={handleChange}/>

                                  </Box>
                              </Box>
                        </Grid>
                      </Grid>
                    ))}
                       
                    </Grid>
                </Grid>
          </Box>
      </Box>
  );
};

export default SetGoalComponent;