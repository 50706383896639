import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import "./AddControllers.css";
import "../../../Assets/Css/Responsive.css";
import { useForm } from "react-hook-form";
import Input from "../../../Components/InputComponent/Input";
import ButtonComponent from "../../../Components/ButtonComponent/ButtonComponent";
import { GetApiParam, postApi, updateApi } from "../../../Api/Api";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

const AddControllers = (props) => {
  const { handleClose, open, editValue, controllerId, AddUpdateController } = props;
  const [loading, setLoading] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: 15,
    right: 15,
    transform: "translateY(-50%)",
    maxWidth: 1000,
    bgcolor: "#042835",
    color: "#ffffff",
    boxShadow: 24,
    p: 4,
    margin: "1rem auto",
    borderRadius: 5,
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editValue) {
      const fetchControllerDetails = async () => {
        const endpoint = "/controller/";
        try {
          const response = await GetApiParam(endpoint, { id: controllerId });
          const controllerDetails = response.data.data[0];
          setValue("controller_name", controllerDetails.controller_name);
          setValue("serial_no", controllerDetails.serial_no);
          setValue("mac_address", controllerDetails.mac_address);
        } catch (error) {
          console.error("Error fetching controller details:", error);
        }
      };
      fetchControllerDetails();
    }
  }, [editValue, controllerId, setValue]);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    const ControllerName = data.controller_name.trim();
    const endpoint = editValue ? `/controller/${controllerId}` : "/controller/";
    const apiFunction = editValue ? updateApi : postApi;

    const formatedData = {
      ...data,
      hospital_id: 0,
      role_id: 0,
      controller_name: ControllerName
    };

    try {
      setLoading(true)
      const responseData = await apiFunction(
        endpoint,
        editValue ? { ...formatedData, id: controllerId } : formatedData
      );
      if (responseData?.status === 200) {
        reset();
        handleClose();
        AddUpdateController();
        toast.success(responseData?.data?.message);
        setLoading(false)
      } else {
        toast.error(responseData?.data?.error);
        setLoading(false)
      }
    } catch (error) {
      console.log("Error:", error);
      setLoading(false)
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-shadow">
          <Box className="modal-header">
            <Typography
              id="modal-modal-title"
              className="text-primery"
              variant="h5"
              component="h5"
            >
              Controller
            </Typography>
            <Button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                  fill="white"
                />
              </svg>
            </Button>
          </Box>
          <Box
            className="modal-body"
            id="modal-modal-description"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <Input
                      label="Controller Name"
                      placeholder="Apollo"
                      type="text"
                      name="controller_name"
                      requiredFeild="requiredFeild"
                      register={register}
                      validationRules={{
                        required: "Controller Name is required",
                        pattern: {
                          value: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
                          message: 'Invalid Controller Name'
                        },
                      }}
                    />
                    {errors.controller_name && (
                      <p className="error-message">
                        {errors.controller_name.message}
                      </p>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <Input
                      label="Serial Number"
                      placeholder="2c:cf:67:28:2d:dd"
                      type="text"
                      name="serial_no"
                      requiredFeild="requiredFeild"
                      register={register}
                      validationRules={{
                        required: "Serial Number is required",
                      }}
                    />
                    {errors.serial_no && (
                      <p className="error-message">
                        {errors.serial_no.message}
                      </p>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <Input
                      label="Mac Address"
                      placeholder="Pitts"
                      type="text"
                      name="mac_address"
                      requiredFeild="requiredFeild"
                      register={register}
                      validationRules={{
                        required: "Mac Address is required",
                      }}
                    />
                    {errors.mac_address && (
                      <p className="error-message">
                        {errors.mac_address.message}
                      </p>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Box className="form-group">
                    <Box className="btn-wrapper">
                      <LoadingButton type="submit" className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                        {editValue ? 'Update Controller' : 'Add Controller'}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddControllers;
