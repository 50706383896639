import { Box, InputLabel, TextField, FormControl } from '@mui/material'
import React from 'react'
import "./Input.css";


const Input = (props) => {
    const { label, placeholder, type, register, name, validationRules, InputProps, disabled, autoFocus, requiredFeild } = props
    return (
        <Box
            noValidate
            className='input-box'

        >
            <InputLabel shrink className='input-label'>
                {label} <span style={{ display: requiredFeild === 'requiredFeild' ? 'inline-block' : 'none' }}>*</span>
            </InputLabel>
            <FormControl variant="standard">
                <TextField
                    autoFocus={autoFocus}
                    id={`bootstrap-input-${name}`}
                    placeholder={placeholder}
                    className="input-wrap"
                    disabled={disabled}
                    type={type}
                    {...register(name, validationRules)}
                    InputProps={InputProps}
                />
            </FormControl>

        </Box>
    )
}

export default Input

