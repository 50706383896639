import React, { useEffect, useState } from "react";
import "../MyPatients/MyPatients.css";
import { Box, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddPhysician from "./Add physician/AddPhysician";
import { GetApi } from "../../Api/Api";
import PhysicianTable from "./components/PhysicianTable";
import { Toaster } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../Context/SearchContext";

const Physician = () => {
  const [open, setOpen] = useState(false);
  const [physicianData, setPhysicianData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { searchQuery, setSearchQuery } = useSearch();


  const fetchPhysicianData = async (page, rowsPerPage) => {
    const endpoint = `/physicians?page=${page}&rowperpage=${rowsPerPage}`;
    try {
      const response = await GetApi(endpoint);
      setPhysicianData(response.data.data);
      setPaginationData(response.data.pagination)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhysicianData(currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const AddUpdatePhysician = () => {
    fetchPhysicianData(currentPage + 1, rowsPerPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Physicians
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" gap={2}>
            <Paper component="form" className="search-input">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="search"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon sx={{ color: "var(--whiteLight)" }} />
              </IconButton>
            </Paper>
            <ButtonComponent
              buttonText="Add physician"
              buttonVariant="contained"
              buttonStyle={{ borderRadius: "100px" }}
              startIcon={<AddIcon />}
              onClick={handleOpen}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <PhysicianTable
          physicianData={physicianData}
          AddUpdatePhysician={AddUpdatePhysician}
          loading={loading}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          currentPage={currentPage}
          paginationData={paginationData}
        />
      </Box>
      <AddPhysician
        open={open}
        handleClose={handleClose}
        AddUpdatePhysician={AddUpdatePhysician}
      />
    </Box>
  );
};

export default Physician;
