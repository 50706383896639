import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { GetApi } from '../Api/Api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState({});

  return (
    <UserContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children} {/* Render children only when patientData has been fetched */}
    </UserContext.Provider>
  );
};


export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
