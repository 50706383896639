import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

const VideoPlayer = () => {
  const location = useLocation();
  const videoUrl = decodeURIComponent(new URLSearchParams(location.search).get('videoUrl'));

  return (
    <Box>
      <Box style={{ maxWidth: "100%", margin: '150px auto auto auto' }}>
        <video controls style={{ height: 'auto', maxHeight: "calc(100vh - 170px)" }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
};

export default VideoPlayer;


