import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Modal, Typography, TextField, Autocomplete, FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material'
import './AddPatients.css'
import { Controller, useForm } from 'react-hook-form'
import 'react-phone-number-input/style.css'
import Input from '../../../Components/InputComponent/Input'
import { useDevice } from '../../../Context/DeviceContext'
import { GetApiParam, postApi, updateApi } from '../../../Api/Api'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const AddPatients = (props) => {
  const { handleClose, open, editValue, patientId, AddUpdatePatient, UpdateUserDetails } = props
  const { selectedDevice, deviceLabel } = useDevice();
  const [loading, setLoading] = useState();
  const [isValueSelected, setIsValueSelected] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm()



  useEffect(() => {
    if (editValue) {

      const fetchPatientDetails = async () => {
        const endpoint = '/patient/'

        try {
          const response = await GetApiParam(endpoint, { id: patientId })
          const patientDetails = response.data.data[0];
          setValue('title', patientDetails.title);
          setValue('patient_name', patientDetails.patient_name);
          setValue('patient_nick_name', patientDetails.patient_nick_name);
          setValue('patient_code', patientDetails.patient_code);
          setValue('reference_name', patientDetails.reference_name);
          setValue('age', patientDetails.age);
          setValue('gender', patientDetails.gender);
          setValue('weight', patientDetails.weight);
          setValue('height', patientDetails.height);
          setValue('phone_no', patientDetails.phone_no);
          setValue('email_id', patientDetails.email_id);
          setValue('disease', patientDetails.disease);
          setValue('address', patientDetails.address);

        } catch (error) {
          console.error('Error fetching patient details:', error);
        }
      };
      fetchPatientDetails();
    }
  }, [editValue, patientId, setValue, UpdateUserDetails]);

  const genderOptions = ['Male', 'Female', 'Other'];
  const titleOptions = ['Mr.', 'Ms.']


  const userDetails = JSON.parse(localStorage.getItem("user"));

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const PatientName = data.patient_name.trim();
    // const PatientNickName = data.patient_nick_name.trim();
    const PatientRefernceName = data.reference_name.trim();
    const PatientDisease = data.disease.trim();
    const PatientAddress = data.address.trim();


    const formatedData = {
      ...data,
      controller_id: 0,
      user_id: userDetails.id,
      patient_name: PatientName,
      // patient_nick_name: PatientNickName,
      reference_name: PatientRefernceName,
      disease: PatientDisease,
      address: PatientAddress
    }

    const endpoint = editValue ? `/patient/${patientId}` : '/patient';
    const apiFunction = editValue ? updateApi : postApi;

    try {
      setLoading(true)
      const responseData = await apiFunction(endpoint, editValue ? { ...formatedData, id: patientId } : formatedData);
      if (responseData?.status === 200) {
        reset();
        handleClose();
        AddUpdatePatient();
        toast.success(responseData?.data?.message);
        setLoading(false);
        UpdateUserDetails();
      } else {
        toast.error(responseData?.data?.error);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error:', error);
      setLoading(false);
    }
  };

  const NameValidation = (value) => {
    return !value.trim() || value.trim().length === 0 ? 'First Name field is required' : true;
  };

  // const onError = (errors) => {
  //   Object.values(errors).forEach((error) => {
  //     toast.error(error.message);
  //   });
  // };

  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Patient
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>

            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Title</InputLabel>
                          <Controller
                            name="title"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={titleOptions}
                                getOptionLabel={(option) => option}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} placeholder="Select Title" />}
                                onChange={(_, value) => {
                                  setValue('title', value ? value : '');
                                }}

                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Name"
                        placeholder="Lindsey"
                        type="text"
                        name="patient_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Patient Name is required',
                          pattern: {
                            value: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
                            message: 'Invalid Patient Name',
                          },
                          validate: NameValidation
                        }} />
                      {errors.patient_name && <p className="error-message">{errors.patient_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Nick  Name"
                        placeholder="Pitts"
                        type="text"
                        name="patient_nick_name"
                        register={register}
                        validationRules={{
                          pattern: {
                            value: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
                            message: 'Invalid Patient Name',
                          },
                        }}
                      />
                      {errors.patient_nick_name && <p className="error-message">{errors.patient_nick_name.message}</p>}

                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Code"
                        placeholder="409866"
                        type="number"
                        name="patient_code"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Patient Code is required',
                          validate: NameValidation
                        }}
                      />
                      {errors.patient_code && <p className="error-message">{errors.patient_code.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Reference Name"
                        placeholder="Lindsey"
                        type="text"
                        name="reference_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Reference Name is required',
                          pattern: {
                            value: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
                            message: 'Invalid Reference Name',
                          },
                          validate: NameValidation
                        }} />
                      {errors.reference_name && <p className="error-message">{errors.reference_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Box className="form-group">
                      <Input
                        label="Age"
                        placeholder="Age"
                        type="number"
                        name="age"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Age is required',
                          min: {
                            value: 0,
                            message: 'Age cannot be less than 0',
                          },
                          max: {
                            value: 120,
                            message: 'Age cannot be more than 120',
                          },
                        }}
                      />
                      {errors.age && <p className="error-message">{errors.age.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>
                            Gender <span style={{ display: 'inline-block', color: 'red' }}>*</span>
                          </InputLabel>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={genderOptions}
                                getOptionLabel={(option) => option}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} placeholder="Select Gender" />}
                                onChange={(_, value) => {
                                  setValue('gender', value ? value : '');
                                  setIsValueSelected(true);
                                }}

                              />
                            )}
                            rules={{ required: 'Gender is required' }}
                          />
                          {errors.gender && <p className="error-message">{isValueSelected ? '' : errors.gender.message}</p>}
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Input
                        label="Weight"
                        placeholder="Weight"
                        type="number"
                        name="weight"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Weight is required',
                          pattern: {
                            value: /^[0-9]+(\.[0-9]+)?$/,
                            message: 'Invalid weight format',
                          },
                        }}
                      />
                      {errors.weight && <p className="error-message">{errors.weight.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Input
                        label="Height (in Ft)"
                        placeholder="5.7"
                        type="text"
                        name="height"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Height is required',
                          pattern: {
                            value: /^[0-9]+(\.[0-9]+)?$/,
                            message: 'Invalid, Non-numeric values',
                          },
                          validate: (value) => value >= 0 && value <= 8 || 'Value outside a reasonable range',
                        }}
                      />
                      {errors.height && <p className="error-message">{errors.height.message}</p>}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">
                          Phone Number <span style={{ display: 'inline-block', color: 'red' }}>*</span>
                        </InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const phoneNumber = formatPhoneNumber(value);
                                if (!phoneNumber) return 'Please provide valid phone number';
                                if (!isValidPhoneNumber(value)) return 'Invalid phone number';
                                return true;
                              }
                            }}
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country="US"
                                placeholder="Enter phone number"
                                inputStyle={{ width: '100%' }}
                                error={errors.phone_no ? true : false}
                              />
                            )}
                          />
                        </FormControl>
                        {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="Enter your Email"
                        type="text"
                        name="email_id"
                        register={register}
                        requiredFeild="requiredFeild"
                        validationRules={{
                          required: 'Email is required.',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Please enter a valid email address'
                          }
                        }}
                      />
                      {errors.email_id && (
                        <p className="error-message">{errors.email_id.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Disease"
                        placeholder="Disease"
                        type="text"
                        name="disease"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Disease is required',
                          validate: NameValidation
                        }}
                      />
                      {errors.disease && <p className="error-message">{errors.disease.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Address"
                        placeholder="Address"
                        type="text"
                        name="address"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Address is required',
                          validate: NameValidation
                        }}
                      />
                      {errors.address && <p className="error-message">{errors.address.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">

                      <Box className="btn-wrapper">
                        <LoadingButton type="submit" className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                          {editValue ? 'Update Patient' : 'Add Patient'}
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddPatients